import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/index.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'Familienpflege Belzer'
      }
    },
    {
      path: '/familienpflege',
      name: 'familienpflege',
      meta: {
        title: 'Familienpflege Belzer - Was ist Familienpflege'
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/familienpflege.vue')
    },
    {
      path: '/kontakt',
      name: 'kontakt',
      meta: {
        title: 'Familienpflege Belzer - Kontakt'
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "ac" */ './views/kontakt.vue')
    },
    {
      path: '/gesetzliche-lage',
      name: 'gesetzliche-lage',
      meta: {
        title: 'Familienpflege Belzer - Gesetzliche Lage'
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "kontakt" */ './views/gesetzliche-lage.vue')
    },
    {
      path: '/zuzahlungen',
      name: 'zuzahlungen',
      meta: {
        title: 'Familienpflege Belzer - Zuzahlungen'
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "kontakt" */ './views/zuzahlungen.vue')
    },
    {
      path: '/einsatzgebiet',
      name: 'einsatzgebiet',
      meta: {
        title: 'Familienpflege Belzer - Einsatzgebiet'
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "kontakt" */ './views/einsatzgebiet.vue')
    },
    {
      path: '/kontakt',
      name: 'kontakt',
      meta: {
        title: 'Familienpflege Belzer - Kontakt'
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "kontakt" */ './views/kontakt.vue')
    },
    {
      path: '/impressum',
      name: 'impressum',
      meta: {
        title: 'Familienpflege Belzer - Impressum'
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "kontakt" */ './views/impressum.vue')
    },
    {
      path: '/datenschutz',
      name: 'datenschutz',
      meta: {
        title: 'Familienpflege Belzer - Datenschutz'
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "kontakt" */ './views/datenschutz.vue')
    },
    {
      path: '/stellenangebote',
      name: 'stellenangebote',
      meta: {
        title: 'Familienpflege Belzer - Stellenangebote'
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "kontakt" */ './views/stellenangebote.vue')
    }
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})
