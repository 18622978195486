<template>
  <div id="app">
    <div class="container">

      <div class="container has-text-centered is-hidden-mobile" style="margin-top:40px;">
        <img src="./assets/kinder.webp" alt="Logo Familienpflege Belzer">

        <img src="./assets/slogan.webp" alt="Slogan Familienpflege Belzer">
      </div>

      <Menu/>

      <router-view/>


      <footer class="footer">
        <div class="content has-text-centered">
          <p>
            <router-link to="/impressum" v-on:click.native="showNav = false" >Impressum</router-link><br/>
            <router-link to="/datenschutz" v-on:click.native="showNav = false" >Datenschutz</router-link>
          </p>
        </div>
      </footer>
    </div>


  </div>
</template>

<script>
  import Menu from './components/menu.vue'

  export default {
  name: 'app',
  components: {
    Menu,
  }
}
</script>

<style>
#app {

}
</style>
