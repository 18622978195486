import Vue from 'vue'
import VueMatomo from 'vue-matomo'
import App from './App.vue'
import router from './router'
import './../node_modules/bulma/css/bulma.css';

Vue.use(VueMatomo, {
  host: 'https://statistik.familienpflege-belzer.de',
  siteId: 2,
  router: router,
  disableCookies: true,
});

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')