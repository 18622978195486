<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">


      <a class="navbar-item" href="/">
        <img src="../assets/kinder.webp" alt="Logo Familienpflege Belzer">
      </a>

      <div class="navbar-burger" @click="showNav = !showNav" :class="{ 'is-active': showNav }">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <div class="navbar-menu" :class="{ 'is-active': showNav }">

      <div class="navbar-start">


        <div class="navbar-item">
          <div class="buttons">
            <router-link to="/kontakt" class="button is-warning" v-on:click.native="showNav = false">Hilfe erhalten</router-link>
          </div>
        </div>


        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            Informieren
          </a>

          <div class="navbar-dropdown">
            <a class="navbar-item">
              <router-link to="/familienpflege" active-class="active" v-on:click.native="showNav = false" >Was ist Familienpflege?</router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/gesetzliche-lage" v-on:click.native="showNav = false">Gesetzliche Lage</router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/zuzahlungen" v-on:click.native="showNav = false">Zuzahlungen</router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/einsatzgebiet" active-class="active" v-on:click.native="showNav = false">Einsatzgebiet</router-link>
            </a>
          </div>

        </div>
        <a class="navbar-item">
          <a class="navbar-item">
            <router-link to="/stellenangebote" active-class="active" v-on:click.native="showNav = false">Stellenangebote</router-link>
          </a>
        </a>

      </div>
    </div>
  </nav>


</template>

<script>
export default {
  name: 'Menu',
  props: {
    msg: String
  },
  data () {
    return {
      showNav: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
