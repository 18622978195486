<template>

    <section class="section">
        <div class="container has-text-left">

            <div class="tile is-ancestor">
                <div class="tile is-vertical is-12">
                    <div class="tile">
                        <div class="tile is-parent is-vertical">
                            <article class="tile is-child box">
                                    <div class="columns">
                                        <div class="column is-one-third has-text-centered">
                                            <img src="../assets/kinder.webp" class="" alt="Logo Familienpflege Belzer">
                                        </div>
                                        <div class="column">
                                            <img src="../assets/slogan.webp" class="has-text-left" alt="Slogan Familienpflege Belzer">
                                        </div>
                                    </div>
                                <p>
                                    Die Familienpflege Belzer ist ein Hilfsangebot für Mütter, die in einer Notsituation ihre Kinder nicht versorgen können, sie
                                    unterstützt die Familie in ihrer häuslichen Umgebung.
                                </p>
                            </article>

                        </div>
                        <div class="tile is-parent">
                            <article class="tile is-child box">
                                <p class="title is-4">Einsatzgebiet</p>
                                <p class="subtitle">Wir helfen im Großraum  zwischen Karlsruhe, Stuttgart, Heidelberg und Heilbronn.</p>
                            </article>
                        </div>
                      <div class="tile is-parent">
                        <article class="tile is-child box"  >
                          <h2 class="title is-5">Hilfe erhalten</h2>
                          <p>
                            Wir begleiten Sie durch die komplette Zeit, von der Anstragstellung bis zur Hilfe vor Ort.
                            Informieren Sie sich hier, wie sie die Hilfe erhalten können.<br/><br/>
                            <router-link to="/kontakt" class="button is-warning" v-on:click.native="showNav = false">Hilfe erhalten</router-link>
                          </p>
                        </article>
                      </div>
                    </div>
                    <div class="tile is-parent">
                        <article class="tile is-child box">
                            <p class="title">Informieren</p>
                            <p class="subtitle">Informieren Sie sich über die Familienpflege</p>
                            <div class="content">
                                <ul>
                                    <li>
                                        <router-link to="/familienpflege" v-on:click.native="showNav = false">Was ist Familienpflege?</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/gesetzliche-lage" v-on:click.native="showNav = false">Die Gesetzliche Lage</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/zuzahlungen" v-on:click.native="showNav = false">Zuzahlungen</router-link>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                </div>
            </div>


        </div>
    </section>

</template>

<script>

    export default {
        name: 'Startseite',
        props: {

        },
        components: {
        },

    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    ul {
        list-style-type: circle;
        padding-left:20px;
        margin-top:20px;
        margin-bottom:20px;
    }
   p {
       padding-bottom: 20px;
   }
</style>
